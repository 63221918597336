import React, { Component } from "react";
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  Row,
  Col,
  Button,
} from "framework7-react";

export default class HomePage extends Component {
  constructor() {
    super();

    this.state = {
      floorSelected: "",
      cards: {},
    };
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      this.getHistory();
      this.clearBreakReceiptData();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.floorSelected !== prevState.floorSelected) {
      this.$f7ready((f7) => {
        this.getHistory();
        this.clearBreakReceiptData();
      });
    }
  }

  clearBreakReceiptData = () => {
    const data = this.$f7.data;
    /* 메시지 열이 깨져서 들어오는 현상 해결*/
    sheetRead(data.sheet.receipt, "A1:JN99999", (response) => {
      if ("valueRanges" in response.result) {
        const receipts = response.result.valueRanges[0].values || [];

        receipts.map((receipt, index) => {
          try {
            if (receipt.length > 4) {
              let filtered = receipt.filter(Boolean);
              let history;
              if (filtered.length === 3) {
                history = [filtered[0], parseInt(filtered[1]), "", filtered[2]];
              } else {
                history = [filtered[0], "", "", filtered[1]];
              }
              let emptyArray = [];
              emptyArray.length = receipt.length;
              emptyArray.fill("");

              sheetUpdate(
                data.sheet.receipt,
                `A${index + 1}`,
                [emptyArray],
                (response) => {
                  console.log(response.result);
                }
              );

              sheetUpdate(
                data.sheet.receipt,
                `A${index + 1}`,
                [history],
                (response) => {
                  console.log(response.result);
                }
              );
            }
          } catch (err) {
            console.log(err);
          }
        });
      }
    });
  };

  getHistory = () => {
    const data = this.$f7.data;
    const { floorSelected, cards, users } = data;
    const dialog = this.$f7.dialog;

    dialog.preloader("데이터 불러오는 중...");

    //구글 스프레드시트 api 사용
    sheetRead(data.sheet.history, "A1:L99999", (response) => {
      if ("valueRanges" in response.result) {
        console.log(response.result);
        const histories = response.result.valueRanges[0].values || [];
        console.log("사용기록데이터 가져오기 성공!", histories.length, "개");

        data.histories = [];

        histories.map((history, index) => {
          try {
            // 사용기록 정보가 현재 미반납 상태인 경우, 최종적으로 대여중인 카드로 나타나도록 반영
            // 또한, 해당 카드를 빌린 사용자가 추가적인 대여가 불가능하도록 처리
            if (history[3] in cards) {
              const userSelected = history[2];
              const cardSelected = history[3];
              const type = history[4];

              //카드상태가 0이면, 대여중인것으로 user와 data를 업데이트
              if (parseInt(history[0]) == 0) {
                cards[cardSelected].user = userSelected;
                cards[cardSelected].type = type;
                cards[cardSelected].range = `A${index + 1}`;
                cards[cardSelected].data = history;

                users[userSelected].card = cardSelected;
              } else {
                //카드상태가 1이면, 반납완료된 것이므로 데이터 리셋
                cards[cardSelected].user = null;
                cards[cardSelected].range = null;
                cards[cardSelected].type = null;
                cards[cardSelected].data = null;

                users[userSelected].card = null;

                //또한 사용내역이 오늘일 경우 참여한 유저는 더이상 다른 식사에 참여될 수 없도록 user정보(lunch/dinner) 변경
                // => 전날 카드를 대여하고 다음날 반납하는 경우 등이 복잡하여 구현하지 않음
              }

              data.histories.push(history);
            }
          } catch (err) {
            console.log(err);
          }
        });

        this.setState({ floorSelected, cards });
        console.log("카드상태 업데이트", cards);
      }
      dialog.close();
    });
  };

  updateSelectedFloor = () => {
    this.setState({ floorSelected: this.$f7.data.floorSelected });
  };

  dialogSelectFloor = () => {
    const dialog = this.$f7.dialog;
    dialog
      .create({
        title: "단말기 위치를 알려주세요.",
        text: "선택한 층에 해당하는 카드만 대여/반납 가능합니다.",
        buttons: [
          {
            text: "3F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "3F";
              this.updateSelectedFloor();
            },
          },
          {
            text: "10F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "10F";
              this.updateSelectedFloor();
            },
          },
          {
            text: "11F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "11F";
              this.updateSelectedFloor();
            },
          },
          {
            text: "12F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "12F";
              this.updateSelectedFloor();
            },
          },
          {
            text: "14F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "14F";
              this.updateSelectedFloor();
            },
          },
          {
            text: "관리자",
            bold: true,
            color: "black",
            onClick: () => {
              if (prompt("비밀번호를 입력하세요.") == "wendy") {
                this.$f7.data.floorSelected = "admin";
                this.$f7router.navigate("/admin/");
              } else {
                alert("비밀번호가 틀렸습니다. 새로고침 후 다시 시도해주세요.");
              }
            },
          },
        ],
        verticalButtons: true,
      })
      .open();
  };

  render() {
    const { floorSelected, cards } = this.state;
    const cardsArray = Object.values(cards);
    cardsArray.sort(function (a, b) {
      return a.serial < b.serial ? -1 : a.serial > b.serial ? 1 : 0;
    });

    const cardToRental = [];
    const cardToReturn = [];
    cardsArray.map((card, i) => {
      if (card.floor === floorSelected) {
        if (card.user) cardToReturn.push(card);
        else cardToRental.push(card);
      }
    });

    return (
      <Page name="home">
        <Navbar noShadow noHairline>
          <img
            src="static/icons/apple-touch-icon.png"
            width="48px"
            style={{
              verticalAlign: "bottom",
              marginRight: 16,
              marginLeft: 26,
              marginBottom: 0,
            }}
          />
          버카드
          <a
            href="javascript:handleSignOutClick();"
            slot="nav-right"
            style={{ color: "gray", fontSize: 16, marginRight: 26 }}
          >
            로그아웃
          </a>
        </Navbar>
        <BlockTitle medium>
          {"위치 :    "}
          <span className="text-highlight">{floorSelected}</span>
          <button
            className="col button button-fill color-yellow"
            onClick={this.dialogSelectFloor}
            style={{ width: "50px", display: "inline", marginLeft: "10px" }}
          >
            변경
          </button>
        </BlockTitle>
        <Row>
          <Col width="50">
            <BlockTitle medium>대여가능 카드</BlockTitle>
            <Block>
              {cardToRental.map((card, i) => {
                return (
                  <Button
                    key={`rental_${i}`}
                    className="button-card"
                    href={`/rental/${card.number}/`}
                  >
                    <div className="header">{card.number}</div>
                    <div className="title">{card.serial}</div>
                  </Button>
                );
              })}
            </Block>
          </Col>
          <Col width="50">
            <BlockTitle medium>이용중인 카드</BlockTitle>
            <Block themeDark>
              {cardToReturn.map((card, i) => {
                return (
                  <Button
                    key={`return_${i}`}
                    className="button-card"
                    href={`/return/${card.number}/`}
                  >
                    <div className="header">{card.user}</div>
                    <div className="title">{card.serial}</div>
                  </Button>
                );
              })}
            </Block>
          </Col>
        </Row>
      </Page>
    );
  }
}
