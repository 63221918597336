import React, { Component } from "react";
import {
  Page,
  Navbar,
  Block,
  BlockTitle,
  List,
  Button,
} from "framework7-react";

export default class IntroPage extends Component {
  constructor() {
    super();
    this.state = {
      gapiAuthStatus: false,
    };
  }

  render() {
    return (
      <Page name="intro">
        <Block inset>
          <BlockTitle large>
            <img
              src="static/icons/apple-touch-icon.png"
              width="48px"
              style={{
                verticalAlign: "bottom",
                marginRight: 16,
                marginBottom: -4,
              }}
            />
            버카드
          </BlockTitle>
          <BlockTitle medium>
            {gapiAuthStatus
              ? ""
              : "인증된 계정으로 구글 로그인 후 사용가능합니다."}
          </BlockTitle>
          {!gapiAuthStatus && (
            <Button
              large
              fill
              raised
              onClick={this.handleGoogleAuth}
              className="button-fixed-bottom"
            >
              로그인
            </Button>
          )}
        </Block>
      </Page>
    );
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      this.checkGoogleAuth();
    });
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      this.checkGoogleAuth();
    });
  }

  handleGoogleAuth = () => {
    if (gapi) {
      if (gapiAuthStatus) {
        handleSignOutClick();
      } else {
        handleSignInClick();
      }
    } else {
      alert("gapi 오류!");
    }
  };

  checkGoogleAuth = () => {
    if (gapiAuthStatus) {
      //구글 로그인이 완료되면, 해당하는 층수를 물어보고 홈화면으로 이동
      this.setState({ gapiAuthStatus });
      if (!this.$f7.data.floorSelected) {
        this.dialogSelectFloor();
      }
    } else {
      return setTimeout(() => {
        this.checkGoogleAuth();
      }, 1000);
    }
  };

  dialogSelectFloor = () => {
    const dialog = this.$f7.dialog;
    dialog
      .create({
        title: "단말기 위치를 알려주세요.",
        text: "선택한 층에 해당하는 카드만 대여/반납 가능합니다.",
        buttons: [
          {
            text: "3F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "3F";
              this.$f7router.navigate("/home/");
            },
          },
          {
            text: "10F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "10F";
              this.$f7router.navigate("/home/");
            },
          },
          {
            text: "11F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "11F";
              this.$f7router.navigate("/home/");
            },
          },
          {
            text: "12F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "12F";
              this.$f7router.navigate("/home/");
            },
          },
          {
            text: "14F",
            bold: true,
            color: "black",
            onClick: () => {
              this.$f7.data.floorSelected = "14F";
              this.$f7router.navigate("/home/");
            },
          },
          {
            text: "관리자",
            bold: true,
            color: "black",
            onClick: () => {
              if (prompt("비밀번호를 입력하세요.") == "wendy") {
                this.$f7.data.floorSelected = "admin";
                this.$f7router.navigate("/admin/");
              } else {
                alert("비밀번호가 틀렸습니다. 새로고침 후 다시 시도해주세요.");
              }
            },
          },
        ],
        verticalButtons: true,
      })
      .open();
  };
}
