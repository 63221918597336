import React, { Component } from "react";
import {
  Page,
  Navbar,
  List,
  Link,
  ListItem,
  Toggle,
  BlockTitle,
  Searchbar,
  Row,
  Button,
  Radio,
  Block
} from "framework7-react";

import Util from "../js/Util";

export default class AdminPage extends Component {
  constructor() {
    super();

    this.isFetching = true;
    this.state = {
      cards: {},
      receipts: [],
      histories: [],
      processMsg: ""
    };
  }

  render() {
    const { processMsg, receipts, histories } = this.state;
    return (
      <Page name="return">
        <Navbar noShadow noHairline title="카드 반납">
          <Link back slot="nav-left" icon="icon-back" />
        </Navbar>

        <BlockTitle medium>
          {"카드영수증 :    "}
          <span className="text-highlight">{receipts.length}</span>
        </BlockTitle>
        <BlockTitle medium>
          {"누락된 사용내역 :    "}
          <span className="text-highlight">{histories.length}</span>
        </BlockTitle>
        <Block>
          <Button
            large
            fill
            raised
            onClick={this.getHistory}
            style={{ fontSize: 24 }}
          >
            1. 누락된 영수증내역 불러오기
          </Button>
        </Block>
        <Block>
          <Button
            large
            fill
            raised
            onClick={this.handleReturnAction}
            style={{ fontSize: 24 }}
          >
            2. 영수증내역과 대조해서 업데이트
          </Button>
        </Block>
        <Block>{processMsg}</Block>
      </Page>
    );
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      const { cards } = f7.data;
      this.setState({ cards }, () => this.getReceipt());
    });
  }

  timeout = (range, time, callback) => {
    var i = range[0];
    callback(i);
    Loop();
    function Loop() {
      setTimeout(function() {
        i++;
        if (i < range[1]) {
          callback(i);
          Loop();
        }
      }, time * 1000);
    }
  };

  handleReturnAction = event => {
    const data = this.$f7.data;
    const dialog = this.$f7.dialog;
    const { receipts, histories, cards } = this.state;
    let count = 0;

    this.timeout([0, histories.length], 3, i => {
      dialog.close();
      dialog.preloader(
        `${i + 1} / ${histories.length} 번째 데이터 처리중 ... `
      );
      const history = histories[i];

      receipts.map(receipt => {
        // receipt : date, card, price, shop
        if (receipt.card === history.cardSelected) {
          if (
            Util.isAfter(receipt.date, history.startDate) &&
            Util.isAfter(history.endDate, receipt.date)
          ) {
            history.receiptChecked.push(receipt);
            history.totalPrice += receipt.price;
          }
        }
      });

      if (history.receiptChecked.length > 0) {
        history.data[0] = 2;
        history.data[5] = Util.receiptStringify(history.receiptChecked);
        history.data[6] = history.totalPrice;
        history.data[9] = Util.formatSMSnum(history.data[9]);
        history.data[10] = Util.formatSMSnum(history.data[10]);
        history.data[11] = Math.max(0, history.totalPrice - history.data[10]);

        sheetUpdate(
          data.sheet.history,
          history.range,
          [history.data],
          response => {
            console.log(
              `${i + 1} / ${histories.length} 번째 데이터 ${
                history.range
              }행에 업데이트 완료`,
              history.data
            );
            count++;
          }
        );
      }

      if (i + 1 >= histories.length) {
        dialog.close();
        alert(
          `누락된 ${histories.length}데이터 중 ${count}개 데이터를 갱신했습니다.`
        );
      }
    });
  };

  getReceipt = () => {
    const data = this.$f7.data;
    const dialog = this.$f7.dialog;

    //구글 스프레드시트 api 사용해 영수증 불러온 후, 반납자의 카드와 일치하는 사용내역 확인

    dialog.preloader("영수증 불러오는 중...");

    sheetRead(data.sheet.receipt, "A1:D99999", response => {
      if ("valueRanges" in response.result) {
        const data = response.result.valueRanges[0].values;
        console.log("영수증데이터 가져오기 성공!", data.length, "개");

        const receipts = [];

        data.map(receipt => {
          try {
            //arr = ["[Web발신]", "[MY COMPANY] 승인", "3206 ", "06/28 15:18", "6,000원 일시불", "커피볶는여자"]
            const arr = receipt[3].split("\n");
            const card = arr[2].toString().trim();
            const date = Util.formatSMStime(arr[3]);
            const shop = arr[5].trim();
            let price = Util.formatSMSnum(arr[4]);

            //카드 결제 취소 시 price 마이너스로 변경
            if (arr[1].indexOf("승인") === -1) price = -price;

            receipts.push({ date, card, price, shop });
          } catch (err) {
            console.log(err);
          }
        });

        this.setState({ receipts }, () => dialog.close());
        console.log("영수증 ", receipts.length, "개 :");
      }
      return;
    });
  };

  getHistory = () => {
    const data = this.$f7.data;
    const { floorSelected, cards } = data;
    const dialog = this.$f7.dialog;

    dialog.preloader("데이터 불러오는 중...");

    //구글 스프레드시트 api 사용
    sheetRead(data.sheet.history, "A1:L99999", response => {
      if ("valueRanges" in response.result) {
        console.log(response.result);
        const data = response.result.valueRanges[0].values || [];
        console.log("사용기록데이터 가져오기 성공!", data.length, "개");

        const histories = [];

        data.map((history, index) => {
          try {
            if (history[3] in cards) {
              // 이용내역이 처리되지 않은 누락내역 검토
              if (parseInt(history[0]) === 1 && history[5] === "") {
                const cardSelected = history[3];
                const startDate = history[1];
                const endDate = history[7];

                histories.push({
                  cardSelected: cardSelected,
                  startDate: startDate,
                  endDate: endDate,
                  range: `A${index + 1}`,
                  data: history,
                  receiptChecked: [],
                  totalPrice: 0
                });
              }
            }
          } catch (err) {
            console.log(err);
          }
        });

        this.setState({ histories }, () => dialog.close());
        console.log("누락 이용내역 :", histories);
      }
    });
  };
}
