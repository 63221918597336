import React from "react";
import Axios from "axios";
import {
  App,
  Panel,
  Views,
  View,
  Statusbar,
  Popup,
  Page,
  Navbar,
  Toolbar,
  NavRight,
  Link,
  Block,
  BlockTitle,
  LoginScreen,
  LoginScreenTitle,
  List,
  ListItem,
  ListInput,
  ListButton,
  BlockFooter,
} from "framework7-react";

import Util from "../js/Util";
import routes from "../js/routes";

export default class extends React.Component {
  constructor() {
    super();

    this.state = {
      gapiAuthStatus: false,
      // Framework7 Parameters
      f7params: {
        name: "buzzni_card", // App name
        theme: "auto", // Automatic theme detection
        // App root data
        data: function () {
          return {
            floorSelected: null,
            // google sheet id
            sheet: {
              receipt: "1hcweiI7Q1UZg7NeD87fKGL7xL0YuLE6nXUU64lIXcis", // 카드영수증 시트
              history: "1rEyCA8Km_fEPjSJdyouc8Ej1gRUgVdhTLwl_0S2qdi4", // 카드 대여/반납내역 시트
            },
            // 카드 리스트 ( Model/Controller에서는 number를 사용하고, View에서만 serial을 사용 )
            cards: {
              //카드번호 : { 카드번호, 시리얼, 위치}
              7306: { number: "7306", serial: "14-A", floor: "14F" },
              7405: { number: "7405", serial: "14-B", floor: "14F" },
              8801: { number: "8801", serial: "14-C", floor: "14F" },
              4202: { number: "4202", serial: "14-D", floor: "14F" },
              4301: { number: "4301", serial: "14-E", floor: "14F" },
              7701: { number: "7701", serial: "14-F", floor: "14F" },
              "0208": { number: "0208", serial: "14-G", floor: "14F" },

              "0406": { number: "0406", serial: "12-A", floor: "12F" },
              6902: { number: "6902", serial: "12-B", floor: "12F" },
              3008: { number: "3008", serial: "12-C", floor: "12F" },
              "0307": { number: "0307", serial: "12-D", floor: "12F" },

              7009: { number: "7009", serial: "11-A", floor: "11F" },
              7108: { number: "7108", serial: "11-B", floor: "11F" },
              7207: { number: "7207", serial: "11-C", floor: "11F" },
              3206: { number: "3206", serial: "11-D", floor: "11F" },
              "0505": { number: "0505", serial: "11-E", floor: "11F" },
              "0604": { number: "0604", serial: "11-F", floor: "11F" },

              2406: { number: "2406", serial: "10-A", floor: "10F" },
              2901: { number: "2901", serial: "10-B", floor: "10F" },
              "0207": { number: "0207", serial: "10-C", floor: "10F" },
              5007: { number: "5007", serial: "10-D", floor: "10F" },
              7817: { number: "7817", serial: "10-E", floor: "10F" },
              4901: { number: "4901", serial: "10-F", floor: "10F" },

              5501: { number: "5501", serial: "3-A", floor: "3F" },
              5402: { number: "5402", serial: "3-B", floor: "3F" },
            },
            // 식사유형별 예산 한도
            budgetByType: {
              점심: 10000,
              저녁: 12000,
              외근: 0,
              회식: 0,
              기타: 0,
              웰컴티타임: 0,
              "스터디/밋업": 0,
            },
            // 버즈니 구성원
            users: {
              //'다니엘' : {name:'다니엘', card:3206, data:null}
              // 이름 : {이름, 대여한카드번호, 대여상세정보 }
            },
            // 구글시트로 불러올 카드 결제 내역
            receipts: [
              // '결제일시', '카드뒷4자리', '결제금액' , '상호명', '대여한사람'
            ],
            // 구글시트로 불러올 카드 대여/반납 내역
            histories: [
              // 상태, 대여일시, 사용자, 카드번호, 유형, 사용내역, 사용금액,	반납일시,	참여자,	총인원,	사용한도,	초과금액
            ],
          };
        },

        // App routes
        routes: routes,
      },
    };
  }

  render() {
    return (
      <App params={this.state.f7params}>
        {/* Status bar overlay for fullscreen mode*/}
        <Statusbar />

        {/* Your main view, should have "view-main" class */}
        <View main className="safe-areas" url="/" />
      </App>
    );
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      this.getUsers();
    });
  }

  getUsers = () => {
    const data = this.$f7.data;
    Axios.get("https://api.buzzni.net/user/v1/users/").then((response) => {
      const users = response.data.sort();
      users.sort(function (a, b) {
        return a.nickname_korean < b.nickname_korean
          ? -1
          : a.nickname_korean > b.nickname_korean
          ? 1
          : 0;
      });
      users.map((user) => {
        data.users[user.nickname_korean] = {
          name: user.nickname_korean,
          card: null,
        };
      });
    });

    //버대리 api로 추후 대체 필요
    // const memberText =
    //   "코니,토니,써니,민,제프리,클레어,데이지,찰리,이리나,라파엘,노아,리암,티거,토르,쵸파,알렉스,모리스,엘린,레오,앤드류,넥스,팀,시드,앤워,짐,주닉,송디,마르코,딘,제리,간달프,카일,메리,헤릭,세미,스티브,진,에밀리,앨런,데미안,벨라,요한,제이슨,로이,엘튼,필립,제이콥,첸,솔,틴틴,에이미,엠마,제이,안나,데이빗,리사,웬디,루시,다니엘,아가사,샐리,오스카,피터,저스틴";
    // const users = memberText.split(",").reverse();

    // users.map(name => {
    //   data.users[name] = { name: name, card: null };
    // });
  };
}
