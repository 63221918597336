
import IntroPage from '../pages/intro';
import HomePage from '../pages/home';
import AdminPage from '../pages/admin';
import RentalPage from '../pages/rental';
import ReturnPage from '../pages/return';

import NotFoundPage from '../pages/404';

var routes = [
  {
    path: '/',
    component: IntroPage,
  },
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/admin/',
    component: AdminPage,
  },
  {
    path: '/rental/',
    component: RentalPage,
  },
  {
    path: '/rental/:cardSelected/',
    component: RentalPage,
  },
  {
    path: '/return/',
    component: ReturnPage,
  },
  {
    path: '/return/:cardSelected/',
    component: ReturnPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
