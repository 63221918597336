import moment from 'moment';

function now(){
  return moment().format("YYYY/MM/DD HH:mm");
}

function isLunch(){
  return (moment().format("HH") < "18");
}

function isAfter(a, b){
  //데이터가 1~2분 오차있을 경우 감안
  return moment(a).add(2, 'minutes').isSameOrAfter(b) && moment(a).subtract(7, 'days').isSameOrBefore(b);
}

function formatSMStime(value){
  try{
    // '06/28 15:18' => '2019/06/28 15:18'
    const year = now().split("/")[0];
    return moment(year+"/"+value).format("YYYY/MM/DD HH:mm");
  } catch(err) {
    return value;
  }
}

function formatSMSnum(value){
  try {
    // '6,000원 일시불' => 6000
    const numberPattern = /\d+/g;
    const filteredList = value.match( numberPattern );
    return parseInt(filteredList.join(""));
  } catch (err) {
    return value;
  }
}

function intComma(value) {
  try{
    return value.toString().replace(/^(-?\d+)(\d{3})/, '$1,$2');
  } catch (err) {
    return value;
  }
}

//한글 초성생성
function koreanInitial(str) {
  const cho = ["ㄱ","ㄲ","ㄴ","ㄷ","ㄸ","ㄹ","ㅁ","ㅂ","ㅃ","ㅅ","ㅆ","ㅇ","ㅈ","ㅉ","ㅊ","ㅋ","ㅌ","ㅍ","ㅎ"];
  let result = "";
  for(let i=0;i<str.length;i++) {
    const code = str.charCodeAt(i)-44032;
    if(code>-1 && code<11172) result += cho[Math.floor(code/588)];
  }
  return result;
}

// 영수증 상세내역 to string 
function receiptStringify(input) {
  const arr = []
  input.map((obj, i) => {
    const {date, card, price, shop} = obj;
    arr.push(`${price < 0 ? "취소" : "승인"},${shop},${price},${date}`);
  })
  return arr.join('\n');
}

export default { now, isAfter, isLunch, formatSMStime, formatSMSnum, intComma, koreanInitial, receiptStringify };