import React, { Component } from "react";
import {
  Page,
  Navbar,
  List,
  Link,
  ListInput,
  ListItem,
  Toggle,
  BlockTitle,
  Searchbar,
  Row,
  Button,
  Radio,
  Block,
} from "framework7-react";

import Util from "../js/Util";

export default class RentalPage extends Component {
  constructor() {
    super();

    // 오후6시 이후에 대여하면  식사유형을 "저녁" 으로 기본선택
    const type = Util.isLunch() ? "점심" : "저녁";

    this.state = {
      users: {},
      cards: {},
      cardSelected: "", // 대여할 카드
      userSelected: "", // 대여자
      type: type, //식사유형
    };
  }

  render() {
    const { cards, users, cardSelected, userSelected, type } = this.state;
    return (
      <Page name="rental">
        <Navbar noShadow noHairline title="카드 대여">
          <Link back slot="nav-left" icon="icon-back" />
        </Navbar>

        {cardSelected in cards && (
          <BlockTitle medium>
            {"카드 :    "}
            <span className="text-highlight">
              {cards[cardSelected].serial}번
            </span>
            <span>{`(${cardSelected})`}</span>
          </BlockTitle>
        )}

        <BlockTitle medium>
          {"식사 :    "}
          <label className="item-radio">
            <input
              name="type"
              type="radio"
              value="점심"
              style={{ display: "none" }}
              checked={type === "점심"}
              onChange={this.handleTypeChanged}
            />
            <span className="text-highlight" style={{ fontSize: "18px" }}>
              점심
            </span>
          </label>
          <label className="item-radio">
            <input
              name="type"
              type="radio"
              value="저녁"
              style={{ display: "none" }}
              checked={type === "저녁"}
              onChange={this.handleTypeChanged}
            />
            <span className="text-highlight" style={{ fontSize: "18px" }}>
              저녁
            </span>
          </label>
          <label className="item-radio">
            <input
              name="type"
              type="radio"
              value="외근"
              style={{ display: "none" }}
              checked={type === "외근"}
              onChange={this.handleTypeChanged}
            />
            <span className="text-highlight" style={{ fontSize: "18px" }}>
              외근
            </span>
          </label>
          <label className="item-radio">
            <input
              name="type"
              type="radio"
              value="회식"
              style={{ display: "none" }}
              checked={type === "회식"}
              onChange={this.handleTypeChanged}
            />
            <span className="text-highlight" style={{ fontSize: "18px" }}>
              회식
            </span>
          </label>
          <label className="item-radio">
            <input
              name="type"
              type="radio"
              value="기타"
              style={{ display: "none" }}
              checked={type === "기타"}
              onChange={this.handleTypeChanged}
            />
            <span className="text-highlight" style={{ fontSize: "18px" }}>
              기타
            </span>
          </label>
          <label className="item-radio">
            <input
              name="type"
              type="radio"
              value="웰컴티타임"
              style={{ display: "none" }}
              checked={type === "웰컴티타임"}
              onChange={this.handleTypeChanged}
            />
            <span className="text-highlight" style={{ fontSize: "18px" }}>
              웰컴티타임
            </span>
          </label>
          <label className="item-radio">
            <input
              name="type"
              type="radio"
              value="스터디/밋업"
              style={{ display: "none" }}
              checked={type === "스터디/밋업"}
              onChange={this.handleTypeChanged}
            />
            <span className="text-highlight" style={{ fontSize: "18px" }}>
              스터디/밋업
            </span>
          </label>
        </BlockTitle>

        <BlockTitle medium>
          {"이름 :    "}
          <span className="text-highlight">{userSelected}</span>
        </BlockTitle>
        <Block style={{ marginBottom: 0 }}>
          <Searchbar
            searchContainer=".search-list"
            searchIn=".item-after"
            inline
            backdrop={false}
            disableButton={false}
            placeholder="사용자 초성검색 (ex: ㄷㄴㅇ)"
          ></Searchbar>
        </Block>
        <List
          noHairlinesMd
          className="search-list searchbar-found"
          style={{ margin: 12, marginBottom: 80 }}
        >
          {Object.values(users).map((user, i) => {
            // 이미 대여한 사람은 리스트에 나타나지 않음
            if (!user.card) {
              return (
                <ListItem
                  key={`member${i}`}
                  radio
                  name="member"
                  value={user.name}
                  title={user.name}
                  checked={userSelected === user.name}
                  onChange={this.handleUserChanged}
                  after={user.name + Util.koreanInitial(user.name)}
                />
              );
            }
          })}
        </List>

        {userSelected != "" && cardSelected != "" ? (
          <Button
            large
            fill
            raised
            onClick={this.handleRentalAction}
            className="button-fixed-bottom"
          >
            대여하기
          </Button>
        ) : (
          <Button
            large
            fill
            raised
            color="gray"
            onClick={this.handleRentalAction}
            className="button-fixed-bottom"
          >
            식사 / 이용자 선택
          </Button>
        )}
      </Page>
    );
  }

  componentDidMount() {
    this.$f7ready((f7) => {
      const { users, cards } = f7.data;
      const cardSelected = this.$f7route.params.cardSelected || "";
      this.setState({ users, cards, cardSelected });
    });
  }

  handleTypeChanged = (event) => {
    const type = event.target.value;
    this.setState({ type });
  };

  handleUserChanged = (event) => {
    const userSelected = event.target.value;
    this.setState({ userSelected });
  };

  handleRentalAction = (event) => {
    const { userSelected, cardSelected, type, users, cards } = this.state;

    // 유효성 체크
    if (userSelected in users && cardSelected in cards) {
      //스프레드시트 입력
      // 상태, 대여일시, 사용자, 카드번호, 유형
      const dialog = this.$f7.dialog;
      const data = this.$f7.data;
      let history = [0, Util.now(), userSelected, cardSelected, type];

      dialog.preloader("데이터를 처리하는 중...");

      sheetAppend(data.sheet.history, [history], (response) => {
        // 액션 완료 후 홈화면 이동
        dialog.close();
        this.$f7router.back("/home/", { force: true });
      });
    }
  };

  //한글 초성생성
  koreanInitial = (str) => {
    const cho = [
      "ㄱ",
      "ㄲ",
      "ㄴ",
      "ㄷ",
      "ㄸ",
      "ㄹ",
      "ㅁ",
      "ㅂ",
      "ㅃ",
      "ㅅ",
      "ㅆ",
      "ㅇ",
      "ㅈ",
      "ㅉ",
      "ㅊ",
      "ㅋ",
      "ㅌ",
      "ㅍ",
      "ㅎ",
    ];
    let result = "";
    for (let i = 0; i < str.length; i++) {
      const code = str.charCodeAt(i) - 44032;
      if (code > -1 && code < 11172) result += cho[Math.floor(code / 588)];
    }
    return result;
  };
}
