import React, { Component } from "react";
import {
  Page,
  Navbar,
  List,
  Link,
  ListItem,
  Toggle,
  BlockTitle,
  Searchbar,
  Row,
  Button,
  Radio,
  Block
} from "framework7-react";

import Util from "../js/Util";

export default class ReturnPage extends Component {
  constructor() {
    super();

    this.isFetching = true;
    this.state = {
      cards: {},
      users: {},
      receiptChecked: [],
      cardSelected: "", // 대여할 카드
      userSelected: "",
      type: "", // 식사 유형
      party: [], // 식사 참여자
      totalPrice: 0,
      budgetPerPerson: 10000
    };
  }

  render() {
    const {
      cards,
      users,
      receiptChecked,
      cardSelected,
      budgetPerPerson,
      party,
      totalPrice
    } = this.state;
    return (
      <Page name="return">
        <Navbar noShadow noHairline title="카드 반납">
          <Link back slot="nav-left" icon="icon-back" />
        </Navbar>

        {cardSelected in cards && (
          <BlockTitle medium>
            {"카드 :    "}
            <span className="text-highlight">
              {cards[cardSelected].serial}번
            </span>
            <span>{`(${cardSelected})`}</span>
          </BlockTitle>
        )}

        {/* <BlockTitle>카드번호</BlockTitle>  
        <Block noHairlinesMd>
          {Object.values(cards).map((card, i) => {
            if(card.user){
              return ( 
                <span key={`card${i}`} style={{fontSize:20, marginRight:12, display:'inline-block'}}>
                  <Radio name="card" value={card.number} 
                  style={{padding:12, paddingBottom:0, textAlign:'center'}}
                  checked={cardSelected === card.number} 
                  onChange={this.handleCardChanged}>
                    <span>{card.serial}</span>
                  </Radio>
                </span>
              )
            }
          })}
        </Block> */}

        <BlockTitle medium>
          {"사용액 :    "}
          <span className="text-highlight">
            {`${Util.intComma(totalPrice)}원`}
          </span>
          {budgetPerPerson >= 0 && (
            <span>
              {`(예산 : ${Util.intComma(party.length * budgetPerPerson)}원 )`}
            </span>
          )}
        </BlockTitle>
        <List noHairlinesMd style={{ margin: 12 }}>
          {receiptChecked.map((receipt, i) => {
            return (
              <ListItem
                key={`receipt${i}`}
                title={`[${receipt.price < 0 ? "취소" : "승인"}] ${
                  receipt.shop
                } : ${Util.intComma(receipt.price)}원`}
                after={`${receipt.date}`}
              />
            );
          })}
          {receiptChecked.length === 0 && (
            <ListItem
              style={{ color: "red" }}
              title={`결제내역 전송 지연으로, 사용내역이 보이지 않을 수 있습니다.`}
            />
          )}
        </List>

        <BlockTitle medium style={{ whiteSpace: "initial" }}>
          {"참여자 :    "}
          {party.map((user, idx) => (
            <span
              className="text-highlight"
              style={{ margin: "2px 16px 14px 0" }}
            >
              {user}{" "}
            </span>
          ))}
        </BlockTitle>
        <Block style={{ marginBottom: 0 }}>
          <Searchbar
            searchContainer=".search-list"
            searchIn=".item-after"
            inline
            backdrop={false}
            disableButton={false}
            placeholder="사용자 초성검색 (ex: ㄷㄴㅇ)"
          ></Searchbar>
        </Block>
        <List
          noHairlinesMd
          className="search-list searchbar-found"
          style={{ margin: 12, marginBottom: 80 }}
        >
          {Object.values(users).map((user, i) => {
            // 식사유형에 따라 가능한 멤버 구분
            return (
              <ListItem
                key={`member${i}`}
                checkbox
                name="member"
                value={user.name}
                title={user.name}
                checked={party.indexOf(user.name) !== -1}
                onChange={this.handlePartyChanged}
                after={user.name + Util.koreanInitial(user.name)}
              />
            );
          })}
        </List>
        {budgetPerPerson === 0 ||
        party.length * budgetPerPerson >= totalPrice ? (
          <Button
            large
            fill
            raised
            onClick={this.handleReturnAction}
            className="button-fixed-bottom"
          >
            반납하기
          </Button>
        ) : (
          <Button
            large
            fill
            raised
            onClick={this.handleReturnAction}
            className="button-fixed-bottom"
          >
            <span>
              {`예산 ${Util.intComma(
                totalPrice - party.length * budgetPerPerson
              )}원 초과`}
            </span>
          </Button>
        )}
      </Page>
    );
  }

  componentDidMount() {
    this.$f7ready(f7 => {
      let { party, budgetPerPerson, type, userSelected } = this.state;
      const { users, cards, budgetByType } = f7.data;
      const cardSelected = this.$f7route.params.cardSelected || "";

      if ("user" in cards[cardSelected]) {
        userSelected = cards[cardSelected].user;
        party.push(userSelected);
      }
      console.log(party);

      if ("type" in cards[cardSelected]) {
        type = cards[cardSelected].type;
        if (type in budgetByType) {
          budgetPerPerson = budgetByType[type];
        }
      }

      this.setState(
        { users, cards, type, cardSelected, userSelected, budgetPerPerson },
        () => this.getReceipt()
      );
    });
  }

  handleCardChanged = event => {
    const cardSelected = event.target.value;
    this.setState({ cardSelected });
  };

  handlePartyChanged = event => {
    const user = event.target.value;
    const party = this.state.party;
    console.log(user, event.target.checked);
    if (event.target.checked) {
      party.push(user);
      this.setState({ party });
    } else {
      var index = party.indexOf(user);
      if (index !== -1) {
        party.splice(index, 1);
        this.setState({ party });
      }
    }
  };

  handleReturnAction = event => {
    const {
      receiptChecked,
      cardSelected,
      party,
      totalPrice,
      budgetPerPerson
    } = this.state;

    // 유효성 체크
    const budgetLimit = party.length * budgetPerPerson;
    const budgetDiff = Math.max(0, totalPrice - budgetLimit);
    if (budgetLimit !== 0 && budgetDiff > 0) {
      if (
        !confirm(
          `예산이 ${Util.intComma(
            budgetDiff
          )}원 초과되었습니다.\n반납하시겠습니까?`
        )
      ) {
        return;
      }
    }

    // 스프레드시트 입력
    // 사용내역, 총사용금액, 반납일시, 참여자, 인원수, 사용한도, 초과금액
    const dialog = this.$f7.dialog;
    const data = this.$f7.data;
    const card = data.cards[cardSelected];
    const historyBefore = card.data;
    const historyAfter = [
      Util.receiptStringify(receiptChecked),
      totalPrice,
      Util.now(),
      party.join(","),
      party.length,
      budgetLimit,
      budgetDiff
    ];
    const history = historyBefore.concat(historyAfter);
    history[0] = 1;

    console.log(card.data, historyBefore, history);
    console.log("스프레드시트에 기존내역 업데이트 :", card.range, history);

    dialog.preloader("데이터를 처리하는 중...");
    sheetUpdate(data.sheet.history, card.range, [history], response => {
      console.log(response.result);

      // 액션 완료 후 홈화면 이동
      dialog.close();
      this.$f7router.back("/home/", { force: true });
    });
  };

  getReceipt = () => {
    const data = this.$f7.data;
    const dialog = this.$f7.dialog;
    const { cardSelected } = this.state;
    let totalPrice = 0;

    //구글 스프레드시트 api 사용해 영수증 불러온 후, 반납자의 카드와 일치하는 사용내역 확인

    dialog.preloader("영수증 불러오는 중...");

    sheetRead(data.sheet.receipt, "A1:D99999", response => {
      if ("valueRanges" in response.result) {
        const receipts = response.result.valueRanges[0].values || [];
        console.log("영수증데이터 가져오기 성공!", receipts.length, "개");

        const receiptChecked = [];

        receipts.map(receipt => {
          try {
            //arr = ["[Web발신]", "[MY COMPANY] 승인", "3206 ", "06/28 15:18", "6,000원 일시불", "커피볶는여자"]
            const arr = receipt[3].split("\n");
            const card = arr[2].toString().trim();
            const date = Util.formatSMStime(arr[3]);
            const shop = arr[5].trim();
            let price = Util.formatSMSnum(arr[4]);

            //카드 결제 취소 시 price 마이너스로 변경
            if (arr[1].indexOf("승인") === -1) price = -price;

            // 영수증 미반납 처리 소유자 확인
            if (card === cardSelected) {
              if (Util.isAfter(date, data.cards[cardSelected].data[1])) {
                receiptChecked.push({ date, card, price, shop });
                totalPrice += price;
              }
            }
          } catch (err) {
            console.log(err);
          }
        });

        this.setState({ receiptChecked, totalPrice });
        dialog.close();

        console.log(
          "사용자 소유 영수증 ",
          receiptChecked.length,
          "개 :",
          receiptChecked
        );
      }
      return;
    });
  };
}
